.ostaleVrsteOsiguranja {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 0 22px;
}

@media (max-width: 991px) {
  .ostaleVrsteOsiguranja {
    padding-left: 20px;
  }
}