.faq {
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.banner {
  display: flex;
  min-height: 570px;
  width: 100%;
  padding-top: 96px;
  background-image: url("../img/FAQ banner.png");
  background-size: cover;
}

.container {
  align-self: center;
  display: flex;
  margin-top: 66px;
  width: 100%;
  max-width: 1154px;
  flex-direction: column;
  padding-bottom: 66px;
}

.title {
  color: var(--Text-txt-primary, #000);
  align-self: start;
  font: 600 32px/1.2 Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.faqList {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.faqItem {
  border-bottom: 1px solid #000;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  margin-top: 40px;
}

.questionWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.question {
  color: var(--Text-txt-primary, #000);
  align-self: stretch;
  width: 933px;
  margin: auto 0;
  font: 600 18px/1.2 Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.toggleButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.toggleIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
}

.answer {
  color: var(--Text-txt-secondary, rgba(60, 60, 67, 0.85));
  margin-top: 20px;
  font: 400 16px/22px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

@media (max-width: 991px) {
  .banner {
    max-width: 100%;
    min-height: 200px;
  }

  .title {
  color: var(--Text-txt-primary, #000);
  align-self: start;
  font: 600 24px/1.2 Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}


  .container {
    max-width: 100%;
    margin-top: 40px;
    padding: 32px;
  }

  .faqList {
    max-width: 100%;
  }

  .faqItem {
    max-width: 100%;
  }

  .question {
    max-width: 100%;
  }

  .answer {
    max-width: 100%;
  }

  .question {
  color: var(--Text-txt-primary, #000);
  align-self: stretch;
  width: 933px;
  margin: auto 0;
  font: 600 14px/1.2 Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}


}