.contentBlock {
  margin-bottom: 20px; /* Optional spacing between blocks */
}

.title {
  text-align: center;  /* Center the title */
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.otherTitle{
  text-align: center;
  font-size: 18px;
}



.content {
  text-align: left; /* Left-align the content */
  font-size: 16px;
  line-height: 1.5;
}



.divider {
  width: 56px;
  height: 2px;
  background-color: rgba(117, 117, 117, 1);
 margin: 0 auto 10px auto; /* Center the divider */
}


@media (max-width: 991px) {
  .contentBlock {
    padding: 24px 20px;
  }

  .title{
    font-size: 16px;
    line-height: 24px;
  }

  .content{
    font-size: 14px;
    line-height: 19px;
  }

}

