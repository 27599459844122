.newsPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--White, #fff);
  font-family: Poppins, sans-serif;
}

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1216px;
  padding: 50px 20px;
}

@media (max-width: 991px) {
  .mainContent {
    padding: 0 16px;
  }
}

.postGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 48px;
}

@media (max-width: 991px) {
  .postGrid {
    grid-template-columns: repeat(2, 1fr);
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  .postGrid {
    grid-template-columns: 1fr;
  }
}

.postCard {
  border-radius: 12px;
  border: 1px solid var(--Secondary-100, #e8e8ea);
  background-color: var(--White, #fff);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 392px;
  padding: 16px;
}

.image {
  aspect-ratio: 1.5;
  object-fit: cover;
  width: 100%;
  border-radius: 6px;
}

.content {
  display: flex;
  margin-top: 16px;
  flex-direction: column;
  padding: 8px;
}

.title {
  font-size: 24px;
  color: var(--Secondary-800, #181a2a);
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 20px;
}

.date {
  font-size: 16px;
  color: var(--Secondary-400, #97989f);
  font-weight: 400;
}

.featuredPost {
  border-radius: 12px;
  background-color: rgba(20, 22, 36, 0.4);
  display: flex;
  min-height: 450px;
  width: 100%;
  max-width: 1216px;
  flex-direction: column;
  justify-content: flex-end;
  padding: 282px 40px 40px;
  color: var(--White, #fff);
  background-image: url("../img/20241009_zatvaranje_d_vedran_radovanovic_06.jpg");
  background-size: cover;
}

.content {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.heading {
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  margin-bottom: 24px;
}

.date {
  font-size: 16px;
  font-weight: 400;
}

@media (max-width: 991px) {
  .featuredPost {
    margin-top: 40px;
    padding: 100px 20px 40px;
  }
}

.header {
  display: flex;
  margin-top: 48px;
  width: 100%;
  max-width: 1216px;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  color: var(--Secondary-800, #181a2a);
  font-weight: 600;
  text-align: center;
  line-height: 1.2;
}

.pageInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--White, #fff);
  width: 100%;
  padding: 16px 0;
}

.breadcrumb {
  margin-top: 8px;
  min-height: 24px;
}

@media (max-width: 991px) {
  .header {
    margin-top: 40px;
  }
}