.navbar{
  background-color: white;
}

.contactPage {
  background-color: #2f4156;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.contactHeader {
  text-align: center;
  padding: 85px 0 0;
  color: #fff;
    max-width: 800px;
    margin: auto;
}

.title {
  font-size: 42px;
  font-weight: 800;
  letter-spacing: -1.92px;
  opacity: 0.9;
  margin-bottom: 7px;
}

.subtitle {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.44px;
}

@media (max-width: 991px) {
  .contactHeader {
    padding-top: 40px;
  }

  .title {
    font-size: 40px;
  }
}

.contactSection {
display: flex;
    justify-content: center;
    gap: 30px;
    padding: 20px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.04);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border: 2px solid rgba(10, 13, 23, 0.05);
    background-color: white;
    max-width: 1152px;
    margin: auto;
  max-height: 500px;
      margin-bottom: 32px;
}

.formWrapper {
  flex: 1;
  max-width: 506px;
  padding: 40px;
  font: 400 16px Poppins, sans-serif;
}

.formTitle {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: -0.6px;
  margin-bottom: 27px;
}

.formDescription,
.contactInfo p {
  line-height: 29px;
  opacity: 0.8;
  margin-bottom: 27px;
}

.imageWrapper {
  flex: 1;
  max-width: 516px;
}

.contactImage {
  width: 100%;
  height: auto;
  border-radius: 12px;
  object-fit: contain;
   max-height: 450px;
}

@media (max-width: 991px) {
  .contactSection {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }

  .formWrapper,
  .imageWrapper {
    max-width: 100%;
  }

  .formWrapper {
    padding: 20px;
  }

  .contactSection {
display: flex;
    justify-content: center;
    gap: 30px;
    padding: 20px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.04);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border: 2px solid rgba(10, 13, 23, 0.05);
    background-color: white;
    max-width: 1152px;
    margin-left: 32px;
    margin-right: 32px;
    max-height: none;
      margin-bottom: 32px;
}

  .contactHeader {
  text-align: center;
  color: #fff;
    margin-left: 32px;
    margin-right: 32px;
}

}
