.banner {
  width: 100%;
  padding-top: 96px;
  display: flex;
  justify-content: center;
}

.bannerImage {
  width: 100%;
  max-width: 1152px;
  aspect-ratio: 2.3;
  object-fit: contain;
}

@media (max-width: 991px) {
  .banner {
    padding-top: 40px;
  }
}