.container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  padding: 112px 80px;
  font: 16px Poppins, sans-serif;
}

@media (max-width: 991px) {
  .container {
    padding: 0 20px 100px;
  }
}

.content {
  display: flex;
  width: 100%;
  max-width: 1149px;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 991px) {
  .content {
    max-width: 100%;
  }
}

.title {
  color: #203239;
  font-size: 36px;
  font-weight: 600;
}

.description {
  color: rgba(32, 50, 57, 0.55);
  font-weight: 300;
  line-height: 30px;
  width: 589px;
}

@media (max-width: 991px) {
  .description {
    max-width: 100%;
  }
}

.offerList {
  align-self: stretch;
  display: flex;
  margin-top: 39px;
  align-items: center;
  gap: 30px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.offerCard {
  border-radius: 10px;
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  width: 369px;
  margin: auto 0;
  padding: 21px 0;
  border: 1px solid #eeeede;
}

.offerHeader {
  align-self: flex-start;
  display: flex;
  gap: 25px;
  color: #203239;
  font-weight: 500;
}

.headerBar {
  border-radius: 5px;
  background-color: #2f4156;
  align-self: flex-start;
  display: flex;
  width: 8px;
  height: 30px;
}

.offerTitle {
  width: 100%;
}

.offerContent {
  display: flex;
  margin-top: 7px;
  width: 100%;
  flex-direction: column;
  padding: 0 14px 0 33px;
}

@media (max-width: 991px) {
  .offerContent {
    padding-left: 20px;
  }
}

.offerText {
  color: rgba(32, 50, 57, 0.55);
  font-weight: 300;
  line-height: 30px;
}

.readMore {
  align-self: flex-start;
  display: flex;
  margin-top: 33px;
  gap: 31px;
  color: #203239;
  font-weight: 500;
}

.readMoreText {
  flex-basis: auto;
}

.readMoreIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}