.container {
  background: var(--Light, #c8d9e6);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  font-family: Poppins, sans-serif;
  font-weight: 600;
  padding: 85px 80px;
  margin-top: 100px;
}

@media (max-width: 991px) {
  .container {
    padding: 0 20px;
    padding-bottom: 32px;
  }
}

.header {
  display: flex;
  margin-left: 51px;
  width: 720px;
  max-width: 100%;
  flex-direction: column;
  font-size: 36px;
  color: #203239;
}

.companyName {
  align-self: start;
}

.sectionTitle {
  align-self: end;
}

.contentWrapper {
  display: flex;
  margin-top: 8px;
  width: 100%;
  max-width: 1152px;
  align-items: center;
  gap: 20px;
  font-size: 16px;
  color: #fff;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row;
}

@media (max-width: 991px) {
  .contentWrapper {
    max-width: 100%;
  }
}

.companyImage {
  aspect-ratio: 0.73;
  object-fit: contain;
  object-position: center;
  width: 100%;
  border-radius: 8px;
  align-self: start;
  width: 525px;
  height: 500px;
}

.textContent {
  align-self: end;
  display: flex;
  margin-top: 56px;
  flex-direction: column;
  align-items: start;
  width: 570px;
}

@media (max-width: 991px) {

  .header {
    padding-top: 32px;
}

  .sectionTitle {
  align-self: start;
}

  .textContent {
    max-width: 100%;
    margin-top: 40px;
  }
}

.description {
  color: rgba(32, 50, 57, 1);
  font-weight: 300;
  line-height: 30px;
  align-self: stretch;
}

@media (max-width: 991px) {
  .description {
    max-width: 100%;
    margin-top: -40px;
  }
}

.infoButton {
  align-self: stretch;
  border-radius: 10px;
  background: var(--Primary, #2f4156);
  margin-top: 48px;
  gap: 10px;
  padding: 13px 41px;
  color: white;
  width: 550px;
}

@media (max-width: 991px) {
  .infoButton {
    margin-top: 40px;
    padding: 0 20px;
    color: white;
     width: 350px;
  }

  .dataButton {
  align-self: stretch;
  border-radius: 10px;
  background: var(--Primary, #2f4156);
  margin-top: 16px;
  gap: 0px;
  padding: 0px;
  color: white;
    height: 40px;

}


}

.dataButton {
  align-self: stretch;
  border-radius: 10px;
  background: var(--Primary, #2f4156);
  margin-top: 16px;
  gap: 10px;
  padding: 13px 41px;
  color: white;
   width: 550px;
}

@media (max-width: 991px) {
  .dataButton {
    height: 50px;
        width: 350px;
  }

  .infoButton {
height: 50px;
}


}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}