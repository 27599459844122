.testimonialsWrapper{
    padding-top: 60px;
}

.testimonialsMain{
    max-width: 1152px;
    margin: auto;
    background: #E2E2E2;
    padding: 40px 0px;
    text-align: center;
    font-family: Manrope, sans-serif;
    font-size: 20px;
    border-radius: 10px;
}

.testimonialsMain img{
    width: 130px;
    margin: auto;
}

.address{
    font-size: 15px;
}

@media  screen and (max-width: 994px){
    .testimonialsWrapper{
        padding: 32px 32px
    }

    .testimonialsMain{
        border-radius: 10px;
        padding: 20px 20px;
    }

}