.card {
  border-radius: 10px;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  min-width: 240px;
  width: 360px;
  margin: auto 0;
  padding: 21px 0;
  border: 1px solid #eeedee;
  height: 350px; /* fixed height */
}

.cardHeader {
  align-self: start;
  display: flex;
  gap: 25px;
  color: #203239;
  font-weight: 500;
}

.indicator {
  border-radius: 5px;
  background-color: #2f4156;
  align-self: start;
  display: flex;
  width: 8px;
  height: 30px;
}

.title {
  flex-basis: auto;
  flex-grow: 1;
  font-size: 20px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  width: 100%;
  padding: 0 13px 0 33px;
  flex-grow: 1; /* this will ensure it stretches to fill the available space */
}

@media (max-width: 991px) {
  .cardContent {
    padding-left: 20px;
  }

  .title {
    font-size: 20px;
  }
}

.description {
  color: rgba(32, 50, 57, 0.55);
  font-weight: 300;
  line-height: 30px;
}

.cardFooter {
  align-self: start;
  display: flex;
  gap: 31px;
  color: #203239;
  font-weight: 500;
  margin-top: auto; /* this ensures the footer is at the bottom */
}

.readMore {
  flex-basis: auto;
}

.icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
}

.cardContainer {
  align-self: stretch;
  display: flex;
  margin-top: 100px;
  align-items: center;
  gap: 30px;
  justify-content: center;
  flex-wrap: wrap;
}
