/* Globalno postavi box-sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

.pageContainer {
  display: flex;
  justify-content: space-between;
  max-height: 90vh;
  padding-right: 0;
  max-width: 1600px;
  margin: auto;
  flex-wrap: nowrap; /* Održava horizontalni raspored na većim ekranima */
  transition: opacity 0.5s ease-in-out; /* Samo opacity za glatki prijelaz */
  opacity: 1; /* Prikazuje sliku */
  overflow: hidden; /* Spriječava preklapanje sadržaja izvan kontejnera */
}

/* Za veće ekrane (od 1371px do 1600px) */
@media (max-width: 1600px) and (min-width: 1371px) {
  .rightColumn {
    flex: 1;
    justify-content: flex-end;
    max-width: 45%; /* Ograniči desnu kolonu */
    padding-right: 20px;
  }

  .mainImage {
    max-width: 600px; /* Ograniči maksimalnu širinu slike */
    height: auto;
  }

  .leftColumn {
    max-width: 50%; /* Ograniči lijevu kolonu */
  }
}

/* Za veće ekrane (od 1201px do 1370px) */
@media (max-width: 1371px) and (min-width: 1201px) {
  .pageContainer {
    justify-content: space-between;
  }

  .leftColumn {
    max-width: 45%;
    padding-left: 20px;
  }

  .rightColumn {
    max-width: 55%; /* Ograniči desnu kolonu */
  }

  .mainImage {
    width: 100%; /* Održava proporciju slike */
    max-width: 700px; /* Spriječava da slika preširoko raste */
  }
}

/* Za još veće ekrane */
@media (min-width: 1601px) {
  .rightColumn {
    max-width: 40%; /* Ograniči prostor desne kolone */
  }

  .mainImage {
    max-width: 800px; /* Ograniči maksimalnu širinu slike */
  }

  .leftColumn {
    max-width: 50%; /* Lijeva kolona ne prelazi 50% */
  }
}

.pageContainer {
  display: flex;
  justify-content: space-between;
  max-height: 90vh;
  padding-right: 0;
  max-width: 1600px;
  margin: auto;
  flex-wrap: nowrap; /* Održava horizontalni raspored na većim ekranima */
  transition: opacity 0.5s ease-in-out; /* Samo opacity za glatki prijelaz */
  opacity: 1; /* Prikazuje sliku */
}


.fade-out {
  opacity: 0; /* Sakrij sliku */
}

.fade-in {
  opacity: 1; /* Prikaži sliku */
}


.leftColumn {
  flex: 1;
  max-width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 132px;
  align-items: flex-start;
}

.rightColumn {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0;
}

.mainImage {
  margin-top: -80px;
  width: 100%; /* Održava prilagodljivost */
  max-width: 750px; /* Ograničava maksimalnu širinu */
  height: auto;
  border-radius: 8px;
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.buttonRow {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.calcButton, .learnMoreButton {
  /* Tvoje stilove za gumbe */
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
}

.button img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.activeButton img {
  border: 5px solid #2F4156; /* Dodaj tamnoplavi border kada je slajd aktivan */
  transform: scale(1.05); /* Malo povećaj sliku za naglasak */
}


.buttonContainer button {
  border: none;
  background: transparent;
}

.calcButton {
  background-color: #424241;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}


.learnMoreButton {
  background-color: transparent;
  color: #424241;
  padding: 10px 20px;
  cursor: pointer;
  border: 2px solid transparent;
}

.button img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

@media (max-width: 1200px) {
  .pageContainer {
    flex-direction: column; /* Prebaci na column za manje ekrane */
    max-height: none; /* Ukloni maksimalnu visinu */
  }

  .leftColumn {
    padding-left: 20px; /* Manji razmak */
    padding-top: 20px; /* Manji razmak */
    max-width: 100%; /* Potpuno koristi širinu */
    align-items: center; /* Centriraj sadržaj */
    text-align: center; /* Centriraj tekst */
  }

  .rightColumn {
    justify-content: center; /* Centriraj desnu kolonu */
    margin-top: 20px; /* Dodaj razmak iznad */
  }

  .mainImage {
    width: 100%; /* Prilagodi sliku širini roditelja */
    height: auto; /* Održava proporcije */
    margin-top: 0; /* Ukloni negativni margina */
  }

  .button img {
    width: 50px; /* Manje ikone za bolje vidljivost */
    height: 50px; /* Održava proporcije */
  }

  h1 {
    font-size: 28px; /* Smanji veličinu naslova */
  }

  p {
    font-size: 14px; /* Smanji veličinu teksta */
  }
}

@media (max-width: 1371px) and (min-width: 1201px) {
  .pageContainer {
    flex-direction: row; /* Zadrži horizontalni layout */
    justify-content: space-between;
    padding-right: 0;
  }

  .leftColumn {
    padding-left: 20px; /* Smanji padding lijeve kolone */
    max-width: 45%; /* Ograniči širinu lijeve kolone */
  }

  .rightColumn {
    max-width: 55%; /* Ograniči širinu desne kolone */
  }

  .mainImage {
    width: 100%; /* Drži sliku unutar desne kolone */
    max-width: 700px; /* Spriječi da slika bude preširoka */
    height: auto;
  }
}

@media (max-width: 1200px) and (min-width: 434px) {
  .mainImage {
    width: 90vw; /* Postavi širinu slike na 90% širine viewporta */
    height: auto; /* Zadrži proporcije slike */
    max-width: 600px; /* Ograniči maksimalnu širinu slike */
  }
}


@media (min-width: 1350px) {
    .mainImage {
        height: 800px; /* Prilagodi visinu slici da bude visoka kao cijeli prozor preglednika */
        object-fit: cover; /* Ova opcija zadržava omjer slike i ispunjava prostor */
    }
}

@media (min-width: 600px) and (max-width: 1195px){
      .mainImage {
    width: 80vw;
    height: 40vh;
    margin-top: 32px;
    position: relative; /* Omogućava da progress bar bude unutar slike */
  }

}

/* Media Query for Smaller Screens */
@media (max-width: 1200px) {
  .pageContainer {
    flex-direction: column;
    max-height: auto;
  }

  .leftColumn {
    padding-left: 32px;
    padding-top: 32px;
    max-width: 100%;
    text-align: left;
  }


  .mainImage {
    width: 80vw;
    height: 60vh;
    margin-top: 32px;
    position: relative; /* Omogućava da progress bar bude unutar slike */
  }

.progressBar {
    position: absolute;
    top: 0; /* Pozicionira progress bar na vrh slike */
    left: 32px; /* Dodaje prazan prostor s lijeve strane */
    right: 32px; /* Dodaje prazan prostor s desne strane */
    height: 10px; /* Visina progress bar-a */
    background-color: #2F4156; /* Postavi boju progress bar-a */
    width: calc(100% - 64px); /* Održava širinu uz 32px prazan prostor sa svake strane */
    transition: width 0.05s; /* Glatka promjena širine */
}




  .leftColumn p,
  .leftColumn h1,
  .buttonRow {
    width: 400px;
  }

  .rightColumn {
    justify-content: center;
  }

  .button img {
    width: 24px;
    height: 24px;
  }

  .mainImage {
    width: 80vw;
    height: 30vh;
    margin-top: 32px;
  }

  h1 {
    font-size: 32px;
  }

  .leftColumn p,
  .leftColumn h1,
  .buttonRow {
    width: 350px;
  }
}

.fade-enter {
  opacity: 0;
  transform: scale(0.95); /* Smanji sliku za bolji efekt */
}

.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; /* Dodaj prijelaz */
}

.fade-exit {
  opacity: 1;
  transform: scale(1);
}

.fade-exit-active {
  opacity: 0;
  transform: scale(0.95); /* Smanji sliku prilikom izlaska */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; /* Dodaj prijelaz */
}

.rightColumn {
    position: relative; /* Dodaj ovu liniju da se progress bar može pozicionirati unutar ove sekcije */
}

.progressBar {
    position: absolute; /* Pozicioniraj progress bar apsolutno unutar .rightColumn */
    bottom: 0; /* Postavi na donji rub */
    left: 0; /* Počni od leve ivice */
    height: 10px; /* Visina progress bar-a */
    background-color: #2F4156; /* Postavi boju progress bar-a */
    transition: width 0.05s; /* Glatka promena širine */
}

