.servicesSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0 96px;
}

.sectionTitle {
  font: 700 36px/1.4 Poppins, -apple-system, Roboto, Helvetica, sans-serif;
  color: #232323;
  text-align: center;
  max-width: 490px;
}

.decorativeIcon {
  aspect-ratio: 29.41;
  object-fit: contain;
  object-position: center;
  width: 56px;
  margin-top: 24px;
}

.serviceGrid {
  display: flex;
  margin-top: 96px;
  width: 100%;
  align-items: center;
  gap: 70px;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1154px;
}

.serviceImage {
  aspect-ratio: 0.95;
  object-fit: contain;
  object-position: center;
  width: 273px;
  filter: drop-shadow(0px 2px 4px rgba(40, 37, 35, 0.04)) drop-shadow(0px 16px 32px rgba(40, 37, 35, 0.1));
  min-width: 240px;
  flex-grow: 1;
}

@media (max-width: 991px) {
  .servicesSection {
    padding: 40px 20px;
  }

  .sectionTitle {
    max-width: 100%;
  }

  .serviceGrid {
    margin-top: 40px;
  }
}