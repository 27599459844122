.contactSection {
  display: flex;
  font-family: Poppins, sans-serif;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
}

.contactInfo {
  justify-content: center;
  align-items: center;
  border-radius: 0;
  background: #2f4156;
  display: flex;
  min-width: 240px;
  padding: 96px 0;
  flex-direction: column;
  color: #fff;
  flex: 1;
  flex-basis: 0%;
}

@media (max-width: 991px) {
  .contactInfo {
    max-width: 100%;
  }
}

.contactInfoContent {
  display: flex;
  width: 469px;
  max-width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.companyName {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
}

@media (max-width: 991px) {
  .companyName {
    max-width: 100%;
  }
}

.contactDetails {
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  margin-top: 24px;
}

@media (max-width: 991px) {
  .contactDetails {
    max-width: 100%;
  }
}

.callToAction {
  background-color: #e6eaf0;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  color: #232323;
  font-weight: 700;
  line-height: 1.4;
  justify-content: center;
  flex: 1;
  flex-basis: 0%;
  padding: 48px 0;
}

@media (max-width: 991px) {
  .callToAction {
    max-width: 100%;
  }
}

.callToActionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
}

.divider {
  min-height: 2px;
  margin-top: 24px;
  width: 56px;
  border: 2px solid #757575;
}