.historySection {
  display: flex;
  flex-direction: column;
  font-family: Poppins, sans-serif;
}



@media (max-width: 991px) {
  .historySection {
    max-width: 100%;
  }
}