.aboutSection {
  display: flex;
  justify-content: center;
  padding: 96px 0;
  margin-top: -110px;
  z-index: 10;
  font-family: Poppins, sans-serif;
}

.contentWrapper {
  width: 956px;
  max-width: 100%;
  padding: 86px 50px;
  background-color: #fff;
  border-radius: 0;
  box-shadow: 0 16px 32px -4px rgba(40, 37, 35, 0.1), 0 2px 4px 0 rgba(40, 37, 35, 0.04);
  text-align: center;
}

.contentWrapper h1{
  text-align: center;
  font-size: 40px;
}

@media (max-width: 991px) {
  .aboutSection {
    padding: 40px 20px;
  }

  .contentWrapper{
    padding: 26px 0;
  }

}