.mainWrapper {
  justify-content: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}


.content {
    display: flex;
    margin-top: 32px;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.mainContent {
    display: flex;
    align-items: start;
    gap: 32px;
    justify-content: start;
}

.blogArticle {
    display: flex;
    max-width: 1152px;
    flex-direction: column;
    align-items: center;
}

.blogInfo {
    display: flex;
    width: 100%;
    max-width: 1156px;
    flex-direction: column;
    align-items: start;
    font-family: Poppins, sans-serif;
}

.heading {
    width: 100%;
    font-size: 36px;
    color: #000;
    font-weight: 600;
    line-height: 40px;
}

.shortInfo {
    align-self: stretch;
    margin-top: 20px;
    gap: 24px;
    font-size: 14px;
    color: #696a75;
    font-weight: 400;
    line-height: 1;
}

.mainImage {
    aspect-ratio: 1.89;
    object-fit: contain;
    object-position: center;
    width: 100%;
    border-radius: 12px;
    margin-top: 32px;
}

.introduction {
    width: 100%;
    margin-top: 32px;
    color: #3b3c4a;
    font: 400 20px/32px "Source Serif Pro", -apple-system, Roboto, Helvetica, sans-serif;
}

.section {
    display: flex;
    margin-top: 32px;
    width: 100%;
    flex-direction: column;
}

.sectionTitle {
    color: #181a2a;
    font: 600 24px/1 "Work Sans", -apple-system, Roboto, Helvetica, sans-serif;
}

.sectionContent {
    color: #3b3c4a;
    margin-top: 16px;
    font: 400 20px/32px "Source Serif Pro", -apple-system, Roboto, Helvetica, sans-serif;
}

.sectionImage {
    aspect-ratio: 2.51;
    object-fit: contain;
    object-position: center;
    width: 100%;
    border-radius: 12px;
    margin-top: 32px;
}

@media (max-width: 991px) {
    .content,
    .mainContent,
    .blogInfo,
    .heading,
    .introduction,
    .sectionTitle,
    .sectionContent {
        max-width: 100%;
    }

    .content {
  justify-content: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
        padding: 0px 32px;
}


}