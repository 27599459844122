.banner{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 386px;
  background-image: url("../img/History backgrounnd.png");
  background-size: cover;
}

.historyItem {
  display: flex;
  width: 100%;
  padding: 89px 150px;
}

.content {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 40px;

}

.title .subdescription:nth-child(2) {
  color: white;
}

.year {
  font-size: 48px;
  line-height: 1;
  flex-grow: 1;
  width: 86px;
}

.details {
  display: flex;
  flex-direction: column;
  font-size: 32px;
  line-height: 1;
  justify-content: flex-start;
  flex-grow: 1;
  width: 972px;
}

.title {
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
}

.description,
.subdescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 25px;
}


.subtitle {
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  margin-top: 25px;
}

@media (max-width: 991px) {
  .historyItem {
    max-width: 100%;
    padding: 0 20px;
  }

  .year {
    font-size: 24px;
  }

  .details {
    max-width: 100%;
  }

  .title,
  .subtitle {
    font-size: 24px;
  }

  .description,
.subdescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 25px;
}

  .banner{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 186px;
  background-image: url("../img/History backgrounnd - mobile.png");
  background-size: cover;
    text-align: center;
    width: 100%;
    padding: 32px;
}

  .content{
     flex-wrap: wrap;
  padding: 32px;
  }

  .banner h1{
    font-size: 24px;
  }


}