.registration {
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.services {
    display: flex;
    margin-top: 21px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 96px 0;
}

.servicesHeader {
    display: flex;
    width: 598px;
    max-width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.servicesTitle {
    font-size: 40px;
    color: #232323;
    font-weight: 600;
    line-height: 1.4;
}

.serviceDivider {
    min-height: 2px;
    margin-top: 24px;
    width: 56px;
    border: 2px solid #757575;
}

.servicesDescription {
    color: #313131;
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
    margin-top: 24px;
}

.serviceSteps {
    display: flex;
    margin-top: 96px;
    width: 100%;
    max-width: 1152px;
    flex-direction: column;
    gap: 96px;
}

.serviceStep {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 96px;
    justify-content: space-between;
}

.serviceStep:nth-child(even) {
    flex-direction: row-reverse;

}

.stepImage {
    aspect-ratio: 1.06;
    object-fit: contain;
    width: 528px;
    min-width: 240px;
}

.stepContent {
    flex: 1;
  width: 100%;
}

.stepHeader {
    margin-bottom: 24px;
}

.stepNumber {
    color: #a0a0a0;
    font: 600 14px/25px Open Sans, sans-serif;
}

.stepTitle {
    color: #232323;
    font: 700 20px/1.4 Poppins, sans-serif;
    margin-top: 12px;
}

.stepDescription {
    color: #313131;
    font: 400 16px/29px Poppins, sans-serif;
}

.stepLink {
    margin-top: 24px;
}

.linkLabel {
    color: #a0a0a0;
    font: 600 14px/25px Open Sans, sans-serif;
}

.link {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #2f4156;
    font: 400 16px/29px Poppins, sans-serif;
    text-decoration: none;
    margin-top: 8px;
}

.linkIcon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 24px;
}

.cta {
    display: flex;
    width: 100%;
    padding: 96px;
    flex-direction: column;
    align-items: center;
    color: #fff;
    text-align: center;
    font: 16px Poppins, sans-serif;
}

.ctaContent {
    align-items: center;
    border-radius: 0;
    background: #2f4156;
    box-shadow: 0 16px 32px -4px rgba(40, 37, 35, 0.1), 0 2px 4px 0 rgba(40, 37, 35, 0.04);
    display: flex;
    width: 100%;
    max-width: 1152px;
    padding: 96px;
    flex-direction: column;
    justify-content: center;
}

.ctaTitle {
    font: 700 24px/1.4 Open Sans, sans-serif;
}

.ctaDescription {
    font-weight: 400;
    line-height: 29px;
    margin-top: 24px;
    max-width: 761px;
}

.ctaButton {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0;
    background: #fff;
    margin-top: 24px;
    padding: 13px 20px;
    gap: 8px;
    color: #313131;
    font-weight: 500;
    border: none;
    cursor: pointer;
}

.ctaButtonIcon {
    aspect-ratio: 1;
    object-fit: contain;
    width: 24px;
}

.footer {
    margin-top: 3%;
    background-color: #e6eaf0;
    display: flex;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    padding: 0 50px 18px;
}

.footerContent {
    display: flex;
    width: 100%;
    max-width: 1255px;
    justify-content: space-between;
    padding: 40px 0;
}

.footerLogo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footerLogoImage {
    aspect-ratio: 2.37;
    object-fit: contain;
    width: 206px;
}

.footerLegalText {
    color: #313131;
    font: 400 12px/22px Poppins, sans-serif;
    margin-top: 11px;
    max-width: 500px;
}

.footerLinks {
    display: flex;
    gap: 20px;
}

.footerLinkColumn {
    display: flex;
    flex-direction: column;
    max-width: 200px;
}

.footerLinkTitle {
    color: #232323;
    font: 700 16px/1.4 Open Sans, sans-serif;
    margin-bottom: 8px;
}

.footerLinkList {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footerLinkList li {
    margin-bottom: 8px;
}

.footerLinkList a {
    color: #313131;
    font: 400 14px/18px Poppins, sans-serif;
    text-decoration: none;
}

.footerBottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1255px;
    padding-top: 20px;
    border-top: 1px solid #b5b5b5;
}

.footerCopyright {
    color: #313131;
    font: 400 16px/29px Poppins, sans-serif;
}

.footerSocial {
    display: flex;
    gap: 12px;
}

.footerSocial a {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerSocial img {
    aspect-ratio: 1;
    object-fit: contain;
    width: 24px;
}

@media (max-width: 991px) {

    .services {
        max-width: 100%;
        padding: 32px;
    }

    .servicesHeader {
        max-width: 100%;
    }

    .serviceSteps {
        margin-top: 40px;
    }

    .serviceStep {
        flex-direction: column;
        gap: 40px;
    }

    .servicesTitle {
        font-size: 40px;
        color: #232323;
        font-weight: 600;
        line-height: 1.4;
    }

    .stepImage {
        max-width: 100%;
    }

    .serviceStep:nth-child(even) {
        flex-direction: column;
        gap: 40px;
    }

    .servicesTitle {
        font-size: 25px;
        color: #232323;
        font-weight: 600;
        line-height: 1.4;
    }


    .servicesDescription {
        color: #313131;
        font-size: 14px;
        font-weight: 400;
        line-height: 29px;
        margin-top: 24px;
    }

    .stepDescription {
        color: #313131;
        font: 400 14px/29px Poppins, sans-serif;
    }

    .link {
        display: flex;
        align-items: center;
        gap: 8px;
        color: #2f4156;
        font: 400 14px/29px Poppins, sans-serif;
        text-decoration: none;
        margin-top: 8px;
    }



    .cta {
        padding: 40px 20px;
    }

    .ctaContent {
        padding: 40px;
    }

    .footer{
        max-width: 100%;
        padding: 0 20px;
    }

    .footerContent {
        flex-direction: column;
        gap: 40px;
    }

    .footerLinks {
        flex-direction: column;
    }
}