.ctaSection {
  padding-bottom: 96px;
}

.ctaContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #2f4156;
  border-radius: 0;
  padding: 96px;
  color: #fff;
  text-align: center;
}

.ctaTitle {
  font: 700 24px/1.4 Open Sans, -apple-system, Roboto, Helvetica, sans-serif;
}

.ctaDescription {
  font-weight: 400;
  line-height: 29px;
  margin-top: 24px;
  max-width: 761px;
}

.ctaButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: none;
  border-radius: 0;
  margin-top: 24px;
  padding: 13px 20px;
  gap: 8px;
  color: #313131;
  font-weight: 500;
  cursor: pointer;
}

.ctaButtonIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
}

@media (max-width: 991px) {
  .ctaContent {
    padding: 40px 20px;
  }

  .ctaDescription {
    max-width: 100%;
  }
}